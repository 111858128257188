function organisations (apiConfig) {
  let data = [
    {
      id: 0,
      title: 'Product Type 1',
      description:
        'This is the first and best product everyone should use. If you don\'t you need to rethink your life',

      credits: '20',
      intervalCount: 2,
      intervalType: 'Month',
      rollover: false,
      subscriptions: ['29f8e40f383d', '94n8fn403h38'],
      priceObject: {
        id: 'po_1234',
        unit_amount: 10000,
        recurring: {
          interval_count: 2,
          interval: 'Month',
        },

      },
    },
    {
      id: 1,
      title: 'Product Type 2',
      description:
        'Except for this one which is way better and gives you even more awesomeness. Please consider this one instead!',

      credits: '21',
      intervalCount: 1,
      intervalType: 'Week',
      rollover: true,
      subscriptions: ['29f8e40f383d'],
      priceObject: {
        id: 'po_1234',
        unit_amount: 15000,
        recurring: {
          interval_count: 1,
          interval: 'Week',
        },

      },
    },
  ]
  return {
    getAll () {
      const rq = apiConfig.api.get('v1/organisations')
      return apiConfig.processCall(rq)
    },
    get (id) {
      const rq = apiConfig.api.get('v1/organisations/' + id)
      return apiConfig.processCall(rq)
    },
    /**
     * Update org
     *
     * @param orgId {String}
     * @param request {Object}
     * @returns {Promise<AxiosResponse>}
     */
    put (orgId, request) {
      const rq = apiConfig.api.put(
        'v1/organisations/' + orgId,
        request,
      )
      return apiConfig.processCall(rq)
    },
    postRequest (orgId) {
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/request',
        {},
      )
      return apiConfig.processCall(rq)
    },
    /**
     * Accept pending requests from users to join the stable(org)
     * @param orgId {String}
     * @param userId {String|undefined}
     * @returns {Promise}
     */
    postAcceptRequest (orgId, userId) {
      let uId = userId ? userId : ''
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/accept/' + uId,
        {},
      )
      return apiConfig.processCall(rq)
    },
    /**
     * This will add a role to a user for your org, or invite them to join your org if they aren't already.
     *
     * @param orgId {String}
     * @param userId {String}
     * @param roles {String[]}
     * @param sendEmail {Boolean}
     * @returns {Promise<>}
     */
    postUserRole (orgId, userId, roles, sendEmail = false) {
      let data = {roles}
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/user/' + userId,
        data,
        {
          params: {
            sendEmail: sendEmail,
          },
        },
      )
      return apiConfig.processCall(rq)
    },
    /**
     * Post a bunch of invites to your org/stable
     *
     * @param orgId {String}
     * @param invites {Object[]}
     * @param sendEmail {Boolean|undefined}
     * @return {Promise<>}
     */
    postUserInvite (orgId, invites, sendEmail = undefined) {
      let data = {invites}
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/invite',
        data,
        {
          params: {
            sendEmail: sendEmail,
          },
        },
      )
      return apiConfig.processCall(rq)
    },
    deleteUserRole (orgId, userId, role) {
      const rq = apiConfig.api.delete(
        'v1/organisations/' + orgId + '/user/' + userId + '/' + role,
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Remove a user from your org/stable
     *
     * @param orgId
     * @param userId
     * @return {Promise<AxiosResponse>}
     */
    removeUser (orgId, userId) {
      const rq = apiConfig.api.delete(
        'v1/organisations/' + orgId + '/banish/' + userId,
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Get all board objects
     * @param orgId {String} orgId
     */
    getAllBoard (orgId) {
      const rq = apiConfig.api.get('v1/organisations/' + orgId + '/boards')
      return apiConfig.processCall(rq)
    },

    /**
     * Get all board objects
     * @param orgId {String} orgId
     */
    // getAllProducts (orgId) {
    getAllProducts () {
      //const rq = apiConfig.api.get('v1/organisations/' + orgId + '/products')
      //return apiConfig.processCall(rq)
      return new Promise(resolve => {
        resolve({data: {data: data.filter(item => !item.archived)}})
      })
    },
    /**
     * Get all board objects
     * @param orgId {String} orgId
     * @param product {Object} product
     * @param id {Integer} product
     */
    putProduct (orgId, product, id = null) {
      // let route = 'v1/organisations/' + orgId + '/products'
      // if (id) {
      //   route = route + '/' + id
      // }
      // const rq = apiConfig.api.put(route, product)
      //return apiConfig.processCall(rq)
      return new Promise(resolve => {
        if (id) {
          data[id] = product
        } else {
          product.id = data.length //for testing just add to end
          data = [...data, product]
        }
        resolve({data: {data: data.filter(item => !item.archived)}})
      })
    },
    /**
     * Get all board objects
     * @param orgId {String} orgId
     * @param product {Object} product
     * @param id {Integer} product
     */
    archiveProduct (orgId, id) {
      // let route = 'v1/organisations/' + orgId + '/products/' + id+'/archive'

      // const rq = apiConfig.api.put(route, {})
      // return apiConfig.processCall(rq)
      return new Promise(resolve => {
        let item = data[id]
        item.archived = true
        resolve({data: {data: data.filter(itm => !itm.archived)}})
      })
    },

    /**
     * Save board object
     * @param orgId {String} orgId
     * @param board {Object} board object to create
     */
    postBoard (orgId, board) {
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/board',
        board,
      )
      return apiConfig.processCall(rq)
    },
    /**
     * Save board object
     * @param orgId {String} orgId
     * @param board {Object} board object to create
     */
    putBoard (orgId, board) {
      const rq = apiConfig.api.put(
        'v1/organisations/' + orgId + '/board',
        board,
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Archive board object
     * @param orgId {String} orgId
     * @param boardId {String}
     */
    deleteBoard (orgId, boardId) {
      const rq = apiConfig.api.delete(
        'v1/organisations/' + orgId + '/board/' + boardId,
      )
      return apiConfig.processCall(rq)
    },
    /**
     * Get Stripe Connect account info
     */
    getStripeAccount (orgId, withLink = false) {
      const rq = apiConfig.api.get(
        'v1/organisations/' + orgId + '/stripe/account',
        {
          params: {
            withLoginLink: withLink,
          },
        },
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Get all invoices for this organisation.
     * @param orgId
     * @param monthYear {String} YYYY-MM-DD should include date to be deseralized
     * @param invoiceStatuses {String[]} ['draft', 'open', 'paid', 'uncollectible', 'void']
     * @param withPastDue {Boolean} should we also return past due invoices regardless of filter
     * @return {Promise<AxiosResponse>}
     */
    getInvoices (orgId, monthYear = undefined, invoiceStatuses = undefined, withPastDue = true) {
      const rq = apiConfig.api.get(
        'v1/organisations/' + orgId + '/stripe/invoices',
        {
          params: {
            monthYear: monthYear,
            invoiceStatuses: invoiceStatuses,
            withPastDue: withPastDue,
          },
        },
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Finalize and Send to customer.
     * @param orgId
     * @param invoiceId
     * @return {Promise<AxiosResponse>}
     */
    finalizeInvoice (orgId, invoiceId) {
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/stripe/invoice/' + invoiceId + '/finalize',
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Send to customer
     * @param orgId
     * @param invoiceId
     * @return {Promise<AxiosResponse>}
     */
    sendInvoice (orgId, invoiceId) {
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/stripe/invoice/' + invoiceId + '/send',
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Void invoice
     * @param orgId
     * @param invoiceId
     * @return {Promise<AxiosResponse>}
     */
    voidInvoice (orgId, invoiceId) {
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/stripe/invoice/' + invoiceId + '/void',
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Remove line item from invoice
     * @param orgId
     * @param invoiceId
     * @param lineItemId
     * @return {Promise<AxiosResponse>}
     */
    removeInvoiceLineItem (orgId, invoiceId, lineItemId) {
      const rq = apiConfig.api.delete(
        'v1/organisations/' + orgId + '/stripe/invoice/' + invoiceId + '/lineitem/' + lineItemId,
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Add line item to invoice
     * @param orgId
     * @param invoiceId
     * @param lineItem {Object}
     * @return {Promise<AxiosResponse>}
     */
    addInvoiceLineItem (orgId, invoiceId, lineItem) {
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/stripe/invoice/' + invoiceId + '/lineitem',
        lineItem,
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Get all packages for this organisation.
     * @param orgId
     * @return {Promise<AxiosResponse>}
     */
    getPackages (orgId) {
      const rq = apiConfig.api.get(
        'v1/organisations/' + orgId + '/packages',
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Create new package for this Organisation.
     * @param orgId
     * @param newPackage
     * @return {Promise<AxiosResponse>}
     */
    postPackage (orgId, newPackage) {
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/package',
        newPackage,
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Update package for this Organisation.
     * @param orgId
     * @param updatePackage {Object}
     * @return {Promise<AxiosResponse>}
     */
    putPackage (orgId, updatePackage) {
      const rq = apiConfig.api.put(
        'v1/organisations/' + orgId + '/package/' + updatePackage.id,
        updatePackage,
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Archive package for this Organisation.
     * @param orgId
     * @param packageId
     * @return {Promise<AxiosResponse>}
     */
    archivePackage (orgId, packageId) {
      const rq = apiConfig.api.delete(
        'v1/organisations/' + orgId + '/package/' + packageId,
      )
      return apiConfig.processCall(rq)
    },

    /**
     * Get all token types for this organisation.
     * @param orgId
     * @return {Promise<AxiosResponse>}
     */
    getTokenTypes (orgId) {
      const rq = apiConfig.api.get(
        'v1/organisations/' + orgId + '/packages/tokentypes',
      )
      return apiConfig.processCall(rq)
    },

    getSubscriptions (orgId) {
      const rq = apiConfig.api.get(
        'v1/organisations/' + orgId + '/subscriptions',
        {
          params: {
            withNeverStarted: false,
          },
        },
      )
      return apiConfig.processCall(rq)
    },

    postSubscription (orgId, create) {
      const rq = apiConfig.api.post(
        'v1/organisations/' + orgId + '/subscription',
        create,
      )
      return apiConfig.processCall(rq)
    },

    putSubscription (orgId, subscriptionId, update) {
      const rq = apiConfig.api.put(
        'v1/organisations/' + orgId + '/subscription/' + subscriptionId,
        update,
      )
      return apiConfig.processCall(rq)
    },

    cancelSubscription (orgId, subId) {
      const rq = apiConfig.api.delete('v1/organisations/' + orgId + '/subscription/' + subId)
      return apiConfig.processCall(rq)
    },
  }
}

export default organisations
