import _ from 'lodash'
import Vue from 'vue'

const USER_ROLES = [
  'USER', 'TRAINER',
  'SUPER_ADMIN', 'ADMIN', 'FARRIER', 'CONTRACTOR',
]

export const stables = {
  namespaced: true,
  state: {
    USER_ROLES: _.clone(USER_ROLES),
    eventTypes: [],
    stables: [],
    stableEventsById: {},
    stableInvoicesById: {},
    pendingInvoicesForStable: {},
    stablePackagesById: {},
    stableBoardListById: {},
    stableResourcesById: {},
  },
  mutations: {
    //use in components with this.$store.commit("user/setUserData", data)
    setStables (state, stables) {
      state.stables.splice(0, state.stables.length, ...stables)
    },
    resetStore (state) {
      state.stables.splice(0)
      state.eventTypes.splice(0, state.eventTypes.length)
      state.stableEventsById = {}
      state.stableInvoicesById = {}
      state.stablePackagesById = {}
    },

    /**
     * These are the common event types for booking events
     *
     * @param state
     * @param eventTypes []
     */
    setEventTypes (state, eventTypes) {
      state.eventTypes.splice(0, state.eventTypes.length, ...eventTypes)
    },

    /**
     * Set
     * @param state
     * @param data {Object} pass in {stableId, events[]}
     */
    setEventsForStable (state, data) {
      Vue.set(state.stableEventsById, data.stableId, data.events)
    },
    /**
     * Set invoices for stable
     * @param state
     * @param data {Object} pass in {stableId, invoices[]}
     */
    setInvoicesForStable (state, data) {
      Vue.set(state.stableInvoicesById, data.stableId, data.invoices)
    },

    /**
     * Set pending invoices for stable
     * @param state
     * @param data {Object} pass in {stableId, invoices[]}
     */
    setPendingInvoicesForStable (state, data) {
      Vue.set(state.pendingInvoicesForStable, data.stableId, data.invoices)
    },

    /**
     * Set packages for stable
     * @param state
     * @param data pass in {stableId, packages[]}
     */
    setPackagesForStable (state, data) {
      Vue.set(state.stablePackagesById, data.stableId, data.packages)
    },

    /**
     * Set board list for stable
     * @param state
     * @param data
     */
    setBoardListForStable (state, data) {
      Vue.set(state.stableBoardListById, data.stableId, data.boardList)
    },
    /**
     * Set resources for stable
     * @param state
     * @param data
     */
    setResourcesForStable (state, data) {
      Vue.set(state.stableResourcesById, data.stableId, data.resources)
    },
  },
  actions: {
    setStables ({commit}, payload) {
      return new Promise(resolve => resolve(commit('setStables', payload)))
    },
    async refreshEventTypes ({commit, state}, {context, force = false}) {
      if (state.eventTypes.length > 0 && !force) return Promise.resolve(state.eventTypes)
      let res = await context.$API.Events.getEventTypes()
      commit('setEventTypes', res.data.data)
    },
    /**
     * @param commit
     * @param state
     * @param context
     * @param force {boolean} force refresh
     * @return {Promise<Object[]>}
     */
    async refreshStables ({commit, state}, {context, force = false}) {
      if (state.stables.length > 0 && !force) return Promise.resolve(state.stables)
      let res = await context.$API.User.getStables()
      commit('setStables', res.data.data)
      return res.data.data
    },
    async refreshEventsForStables ({commit, state}, context, payload) {
      // payload = force
      if (!payload && Object.keys(state.stableEventsById).length > 0) {
        return Promise.resolve()
      }
      await context.$API.User.getEvents().then(res => {
        let data = res.data.data
        data.forEach(stable => {
          commit('setEventsForStable', {
            stableId: stable.id,
            events: stable.events,
          })
        })
      })
    },
    async refreshInvoicesForStables ({commit, getters}, {context, stableId, monthYear, invoiceStatuses}) {
      let stableRef = getters.getStable(stableId)
      let invoices = context.$API.Organisations.getInvoices(stableRef.organisation.id, monthYear, invoiceStatuses)
        .then(res => {
          let data = res.data.data
          commit('setInvoicesForStable', {stableId: stableId, invoices: data})
        })
      let draftInvoices = context.$API.Organisations
        .getInvoices(stableRef.organisation.id, null, ['draft'], false)
        .then(res => {
          let data = res.data.data
          commit('setPendingInvoicesForStable', {stableId: stableId, invoices: data})
        })
      await Promise.all([invoices, draftInvoices])
    },
    async refreshPackagesForStable ({commit, getters}, {context, stableId}) {
      let stableRef = getters.getStable(stableId)
      await context.$API.Organisations.getPackages(stableRef.organisation.id)
        .then(res => {
          let data = res.data.data
          commit('setPackagesForStable', {
            stableId: stableId,
            packages: data,
          })
        })
    },
    async refreshBoardListForStable ({commit, getters}, {context, stableId, force = false}) {
      if (force) commit('setBoardListForStable', {stableId: stableId, boardList: []})
      let stableRef = getters.getStable(stableId)
      await context.$API.Organisations.getAllBoard(stableRef.organisation.id)
        .then(res => {
          let data = res.data.data
          commit('setBoardListForStable', {
            stableId: stableId,
            boardList: data,
          })
        })
    },
    async refreshResourcesForStable ({commit}, {context, stableId, force = false}) {
      if (force) commit('setResourcesForStable', {stableId: stableId, resources: []})
      await context.$API.Stable.getResources(stableId)
        .then(res => {
          let data = res.data.data
          commit('setResourcesForStable', {
            stableId: stableId,
            resources: data,
          })
        })
    },
  },
  getters: {
    //use in components with this.$store.getters["stables/stables"]
    stables (state) {
      return state.stables
    },
    getStable (state) {
      return (stableId) => {
        return state.stables.find(value => value.stable.id === stableId)
      }
    },
    /**
     * Has the stable finished OnBoarding stripe
     * @returns boolean
     */
    stripeOnboarded (state, getters) {
      return (stableId) => {
        let stable = getters.getStable(stableId)
        if (stable && stable.organisation) {
          return stable.organisation.stripeState === 'account_update' && stable.organisation.stripeTransfersEnabled === true
        }
        return false
      }
    },
    /**
     * Has the org started the stripe onboarding process? I.e. show status of stripe, otherwise everwhere else should
     * use the [stripeOnboarded] getter
     * @param state
     * @param getters
     */
    stripeStarted (state, getters) {
      return (stableId) => {
        let stable = getters.getStable(stableId)
        if (stable && stable.organisation) {
          return stable.organisation.stripeState === 'account_update'
        }
        return false
      }
    },
    eventTypes (state) {
      return state.eventTypes
    },
    /**
     * Get Events for currently selected stable. ['stables/eventsForStable'](stableId)
     *
     * @param state
     * @returns {function(*): *|*[]}
     */
    eventsForStable (state) {
      return (stableId) => {
        return (state.stableEventsById[stableId] || [])
          .filter(event => event.type.id !== 5)
      }
    },
    /**
     * Get Events for all stables. ['stables/eventsForStables']
     *
     * @param state
     * @returns {Object}
     */
    eventsForStables (state) {
      const object = state.stableEventsById || {}
      return Object.keys(object).reduce((newObject, stableId) => {
        newObject[stableId] = object[stableId].filter(event => event.type.id !== 5)
        return newObject
      }, {})
    },
    /**
     * Takes an optional roles[] filter.
     *
     * Use in components with this.$store.getters["stables/usersForStable"](stableId)
     */
    usersForStable (state, getters) {
      return (stableId, roles = []) => {
        let roleArray = roles.length > 0 ? roles : USER_ROLES
        let stable = getters.getStable(stableId)
        if (stable) {
          return stable.users.filter(value => roleArray.includes(value.role))
        }
        return []
      }
    },
    /**
     * Same as [usersForStable], but will flatten to the first object for each id (i.e. grouped by 'userId')
     *
     * Use in components with this.$store.getters["stables/groupedUserForStable"](stableId, roles = [])
     */
    groupedUserForStable (state, getters) {
      return (stableId, roles = []) => {
        let users = getters.usersForStable(stableId, roles)
        return _.groupBy(users, 'userId')
      }
    },

    /**
     * Get Invoices for currently selected stable. ['stables/invoicesForStable'](stableId)
     *
     * @param state
     * @return {function(*): *}
     */
    invoicesForStable (state) {
      return (stableId) => {
        return state.stableInvoicesById[stableId] || []
      }
    },

    /**
     * Get Pending Invoices for currently selected stable. ['stables/pendingInvoicesForStable'](stableId)
     * @param state
     * @returns {function(*): *}
     */
    pendingInvoicesForStable (state) {
      return (stableId) => {
        return state.pendingInvoicesForStable[stableId] || []
      }
    },

    /**
     * Get Packages for currently selected stable. ['stables/packagesForStable'](stableId)
     * @param state
     * @return {function(*): *}
     */
    packagesForStable (state) {
      return (stableId) => {
        return state.stablePackagesById[stableId] || []
      }
    },
    /**
     * Get BoardList for currently selected stable. ['stables/boardListForStable'](stableId)
     * @param state
     * @return {function(*): *}
     */
    boardListForStable (state) {
      return (stableId) => {
        return state.stableBoardListById[stableId] || []
      }
    },
    /**
     * Get Resources for currently selected stable. ['stables/resourcesForStable'](stableId)
     * @param state
     * @return {function(*): ResourceResponse[]}
     */
    resourcesForStable (state) {
      return (stableId) => {
        return state.stableResourcesById[stableId] || []
      }
    },
  },
}
